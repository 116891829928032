@import "../_loader.scss";

body {
  section.error {
    background: $bg-color; //position: fixed; left: 0; top: 0; right: 0; bottom: 0; z-index: 99999;
    .panel {
      background: $color-white;
      border-radius: 12px;
      padding: 8rem 7.5rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      text-align: center;
      max-width: 80rem;

      img {
        margin-bottom: 3rem;
        max-width: 85px;
        margin-left: auto;
        margin-right: auto;
      }

      .title {
        color: $color-primary;
        font-size: 2.5rem;
        font-weight: $font-light;

        strong {
          font-weight: $font-bold;
        }
      }
    }

    .header {
      padding: 3rem;
    }

    .footer {
      background: $bg-color;
      border-top: 1px solid $color-lightblue;
      padding: 3rem;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;

      img {
        height: 3rem;
        margin-bottom: 1rem;
      }

      .copy {
        color: $color-grey;
        font-size: 1.3rem;
      }
    }
  }
}
