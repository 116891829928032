//
// Path
//
$path-font: "/assets/fonts" !default;
$path-images: "/assets/images" !default;
$icomoon-font-path: $path-font + "/ojm-docflow";

//
// Color system
//
$color-black: #000000;
$color-ojm: #0a5091;
$color-blue-medium: #3299EB;
$color-aqua: #117e9b;
$color-petrol: #08647d;
$color-anthracite: #515151;
$color-grey: #757575;
$color-steel: #b1b1b1;
$color-lightblue: #d5e0ea;
$color-white: #ffffff;
$color-red: #a01f24;
$color-violet: #994AE2;

// general colors
$color-primary: $color-ojm;
$color-secondary: $color-aqua;
$bg-color: lighten(adjust-hue($color-lightblue, -1), 5.88);
$bg-color-light: #f2f6f9;
$color-aqua-light: rgba(17,126,155, 0.3);
$color-aqua-lighter: #d5e0ea;

// alert message
$color-error: #b01923;
$color-success: #9dc744;
$color-warning: #f55d30;
$color-status-waiting: #f5c330;
$color-status-confirmed: $color-success;
$color-status-not-confirmed: $color-error;

// type colors
$color-document: #666666;
$color-quote: #DA611F;
$color-framework-agreement: #996CC2;
$color-multiprofile: #119B5D;
$color-free-quote: #64D2FF;

// quote status colors
$quote-confirmed: $color-success;
$quote-not-confirmed: $color-error;
$quote-nbf: #aeb5bd;
$quote-to-be-reviewed: $color-warning;
$quote-waiting: #f5c330;
$quote-waiting-for-leader: $quote-waiting;
$quote-draft: #636c78;

//Status colors
$color-billing: rgba(245, 93, 48, 0.1);
$color-approval: rgba(245, 195, 48, 0.1);
$color-billed: rgba(156, 199, 67, 0.1);
$color-for-billing: rgba(176, 25, 35, 0.1);
$color-revisioned: rgba(218, 97, 31, 0.1);
$color-to-be-billed: #E1DDF6;
//
// Spacing
//
$spacer: 1rem !default;

//
// Typography
//
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300&display=swap');
$font-family-sans-serif: "Open Sans", "Helvetica Neue", Arial, sans-serif;
$font-family-base: $font-family-sans-serif;

$font-light: 300;
$font-normal: 400;
$font-semibold: 600;
$font-bold: 700;
$font-weight-base: $font-normal;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$line-height-base: 1.5;
$headings-margin-bottom: ($spacer / 2);
$dt-font-weight: $font-bold;


// Links
$link-color: $color-black;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;

// Paragraphs
$paragraph-margin-bottom: 1rem !default;

// Tables
$table-cell-padding: 0.75rem !default;

// Options
$enable-hover-media-query: false !default;
$enable-rounded: true !default;

// Forms
$input-bg:                       #ffffff;
$input-bg-disabled:              lighten($color-lightblue, 10%);
$input-color:                    $color-black;
$input-border:                   #CFCFCF;

$input-border-radius:            6px;
$input-border-focus:             $color-ojm;
$input-color-placeholder:        $color-grey;

$input-height-base:              4rem;
$form-group-margin-bottom:       3.5rem;

$legend-color:                   $color-anthracite;
$legend-border-color:            $color-lightblue;

$input-group-addon-bg:           $color-white;
$input-group-addon-border-color: transparent;

//Screen size

$screen-xl-min: 1400px;
